<div
  class="temperature-box"
  *ngIf="!loading; else spinner"
>
  <header>
    <h2>🌡<span class="label">{{location.name}}</span></h2>
  </header>
  <div class="temperatures">
    <div class="temperature temperature--primary">
      <span class="value">{{location.lastMeasurmentValue | toFixed}}</span><span class="unit">°C</span>
    </div>
    <div
      class="temperature temperature--minimal"
      *ngIf="location.minimalMeasurmentValue !== null"
    >
      <span class="value">Min: {{location.minimalMeasurmentValue | toFixed}}</span><span class="unit">°C</span>
    </div>
  </div>
  <div
    class="footer footer--warning footer--warning-outdated"
    *ngIf="location.updatedAt | isLocationOutdated"
  >
    <span>Opóźnione: {{ location.updatedAt | relativeTime }}</span>
  </div>
</div>
<div class="arrow"></div>
<ng-template #spinner>
  <termo-spinner></termo-spinner>
</ng-template>
